<script setup lang="ts">
import {
	UserCircleIcon,
	ChevronDownIcon,
	ShoppingCartIcon as ShoppingCartIconOutline,
} from "@heroicons/vue/24/outline"
import { ShoppingCartIcon } from "@heroicons/vue/24/solid"
import { usePage, router, Link } from "@inertiajs/vue3"
import NavLink from "@/Components/Reusable/NavLink.vue"
import SettingsNavLink from "@/Components/Reusable/SettingsNavLink.vue"
import { useEventBus } from "@vueuse/core"
const auth = usePage<SharedProps>().props.auth
const user = auth.user

/**
 * It's hacky but we would have to reset the entire state otherwise
 * Partial reloads with the only option doesn't seem to work because it's shared data.
 */
const bus = useEventBus<number>("cart")
bus.on((event) => {
	auth.cartLength = auth.cartLength + event
})

const logout = () => {
	router.post(
		"/logout",
		{},
		{
			preserveState: false,
		},
	)
}
</script>
<template>
	<nav class="fixed top-0 z-10 flex w-full justify-center bg-white py-2 shadow-lg">
		<div class="mx-4 flex max-w-7xl flex-grow items-center justify-between">
			<Link
				as="img"
				href="/marketplace"
				class="w-[250px] cursor-pointer"
				src="/logo/sno_ads_logo.png"
			/>
			<ul class="flex gap-x-4">
				<NavLink href="/marketplace" prop-name="MarketplacePage">Store</NavLink>
				<NavLink href="/cart" prop-name="Cart">Cart</NavLink>
				<NavLink href="/content" prop-name="ContentManager">Images & Links</NavLink>
				<NavLink href="/ads" prop-name="AdManager">Ad Manager</NavLink>
				<NavLink href="/stats" prop-name="AdStats" hide-default>Stats</NavLink>
				<NavLink href="/settings" prop-name="Settings" hide-default>Settings</NavLink>
				<NavLink href="/billing" prop-name="Billing" hide-default>Billing</NavLink>
			</ul>
			<div class="flex">
				<Link href="/cart">
					<label v-if="auth.cartLength > 0" class="btn m-1 text-secondary">
						<ShoppingCartIcon class="h-6 w-6" />
						<div>{{ auth.cartLength }}</div>
					</label>
					<label v-else class="btn m-1">
						<ShoppingCartIconOutline class="h-6 w-6" />
					</label>
				</Link>
				<div id="user-dropdown" class="dropdown dropdown-hover">
					<label class="btn m-1">
						<UserCircleIcon class="h-6 w-6" />
						<div>{{ user.name }}</div>
						<ChevronDownIcon class="h-4 w-4" />
					</label>
					<ul class="menu dropdown-content rounded-box z-[1] w-52 bg-base-100 p-2 shadow">
						<SettingsNavLink v-if="auth.showBilling" href="/billing" prop-name="Billing"
							>Billing</SettingsNavLink
						>
						<SettingsNavLink v-if="auth.showAdStats" href="/stats" prop-name="AdStats"
							>Ad Stats</SettingsNavLink
						>
						<li>
							<button @click="logout">Logout</button>
						</li>
					</ul>
				</div>
				<div v-if="user.level == 'admin'" class="dropdown dropdown-hover">
					<label class="btn m-1">
						<div>SNO ADMIN</div>
						<ChevronDownIcon class="h-4 w-4" />
					</label>
					<ul class="menu dropdown-content rounded-box z-[1] w-52 bg-base-100 p-2 shadow">
						<li
							:class="{
								'font-bold text-slate-600': $page.component == 'Admin/AdminLogin',
							}"
						>
							<Link href="/admin/login">Login As</Link>
						</li>
						<li
							:class="{
								'font-bold text-slate-600':
									$page.component == 'Admin/AdminContentManager',
							}"
						>
							<Link href="/admin/content">Approve Assets</Link>
						</li>
						<li
							:class="{
								'font-bold text-slate-600': $page.component == 'Admin/SiteManager',
							}"
						>
							<Link href="/admin/sites">Site Manager</Link>
						</li>
						<li
							:class="{
								'font-bold text-slate-600': $page.component == 'Admin/Quickbooks',
							}"
						>
							<Link href="/quickbooks">Quickbooks</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</nav>
</template>
